<template>
  <div>
    <tutorial-overlay
      v-if="uiState.showShootOut"
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="uiState.showShootOut"
      instructor-name="stefecekova"
    />
    <tutorial-message-box
      v-if="uiState.showShootOut"
      key="shootout"
      :header-text="$t('utTutorialStefecekova')"
      :button-yellow-text="yellowText"
      :text="$t('shootoutMessage')"
      :type-write="false"
      style="height: 330px"
    >
      <template
        #message-text
      >
        <div
          style="height:195px"
        >
          <div style="font-weight: bold; text-transform: capitalize">
            {{ $t('shootout') }}:
          </div>
          - {{ $t('shootoutMessage1') }}
          <div style="color: #feb942">
            - {{ $t('shootoutMessage2') }}
          </div>
        </div>
      </template>
    </tutorial-message-box>
  </div>
</template>

<script lang="ts">

import { uiState } from '@/stores'
import {
  TutorialAnne,
  TutorialMessageBox,
  TutorialOverlay,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShootoutContainer',
  components: {
    TutorialMessageBox,
    TutorialAnne,
    TutorialOverlay
  },
  mixins: [WindowAspect],
  data() {

    return {
      uiState: uiState(),
    }

  },
  computed: {
    yellowText() {

      if (uiState().shootOutReady) {

        return this.isMobile() ? this.$t('tapToContinue') : this.$t('clickToContinueTemp')

      }
      return ''

    }
  }
})
</script>

<style lang="less">

</style>
